.header-in-panel{
    background: #f29825;
    padding: 5px 5px;
    color: white;
}
table td.details-control{
    text-align: center;
}
.fileUpload {
    position: relative;
    overflow: hidden;
    margin: 0px;
}
.fileUpload input.upload {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    font-size: 20px;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
}
.fileUpload.input-group-addon.btn{
    background-color: #645570;
    border-color: #6C13B1;
}
.fileUpload.input-group-addon.btn:hover{
    background-color: #9983aa;
    border-color: #6C13B1;
}
.select2-container--default .select2-selection--single:focus, 
.select2-container--default .select2-selection--single:hover, 
.select2-container--default .select2-selection--multiple:focus, 
.select2-container--default .select2-selection--multiple:hover {
    border-color: #558B2F;
    box-shadow: none;
}

.select2-container--default.select2-container--focus .select2-selection--multiple, 
.select2-container--default.select2-container--focus .select2-selection--single, 
.select2-container--default.select2-container--open .select2-selection--multiple, 
.select2-container--default.select2-container--open .select2-selection--single {
    border-color: #558B2F;
    box-shadow: none;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #555;
}

:focus {
    outline: #558B2F auto 1px;
}
.lobipanel .panel-body{
    padding: 0px;
}
.lobipanel .panel-content{
    padding: 15px;
}
.row.same-height{
    display: flex;
    flex-wrap: wrap;
}
.no-form-margin .form-group,
no-margin-bottom{
    margin-bottom: 0px;
}
.ui-pnotify.custom .ui-pnotify-container p{
    margin-bottom: 0px;
}
.ui-pnotify.custom .ui-pnotify-container {
    background-image: none !important;
    border: none !important;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
}
.ui-pnotify.custom .ui-pnotify-title, .ui-pnotify.custom .ui-pnotify-text {
    font-family: Arial, Helvetica, sans-serif !important;
    font-size: 11pt !important;
    padding-left: 25px !important;
    line-height: 14pt !important;
    text-rendering: geometricPrecision !important;
}
.ui-pnotify.custom .ui-pnotify-title {
    font-weight: bold;
}
.ui-pnotify.custom .ui-pnotify-icon {
    float: left;
}
.ui-pnotify.custom .fa {
    margin: 3px;
    width: 33px;
    height: 33px;
    font-size: 33px;
}
p.small-info {
    font-size: 13px;
    opacity: .8;
}
.toggle-type{
    text-align: center;
}
#mysearch .dataTables_filter label,
#mysearch .dataTables_filter label input{
    width: 100%;
}
#mysearch .dataTables_filter label input{
    margin: 0;
}
.panel-actions{
    padding: 10px 15px;
    margin-bottom: 15px;
    background-color: rgba(186, 224, 255, 0.15);
    border: 1px solid #e1e6ef;
}
.sub_menu_parent{
    display:flex;
    position: relative;
}
.sub_menu_parent .link_menu{
    flex-grow: 1;
    padding: 10px 15px;
}
.sub_menu_parent .link_submenu{
    padding: 10px 5px;
}
.sub_menu_parent .link_submenu span{
    line-height: 24px;
    width: 26px;
    text-align: center;
}
.navbar-nav > li > .dropdown-menu:before, .navbar-top-links .dropdown-menu:before{
    right: 13px;
    left:auto;
}
.navbar-nav > li > .dropdown-menu:after, .navbar-top-links .dropdown-menu:after{
    right: 12px;
    left:auto;
}
.navbar-top-links .dropdown-menu{
    margin-top:10px;
}
.navbar-brand{
    padding-top: 0px;
    padding-bottom: 0px;
    text-align: center;
}
.navbar-brand > img{
    height: 100%;
    display:inline-block;
}
.view-header .header-icon{
    display:inline-block;
    float: none;
    line-height: 26px;
    font-size: 26px;
    width: auto;
    margin-top: 0px;
    vertical-align: bottom;
}
.view-header .header-title{
    display:inline-block;
    margin-left: 0px;
}
.breadcrumb{
    padding: 15px;
    margin-bottom: 0px;
}
.tag-role{
    background: #aacd4eFF;
    color: #FFF;
    margin-right: 4px;
    padding: 2px 4px;
    display:inline-block;
}
label.required:after{
    content:'*';
    color: #FF0000;
    margin-left: 5px;
    font-size: 14px;
    line-height: 10px;    
}
#loading-modal{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: none;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    outline: 0;
    background: rgba(0,0,0,0.5);
}
.loader,
.loader:before,
.loader:after {
  background: #ffffff;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.loader {
  color: #ffffff;
  text-indent: -9999em;
  margin: 300px auto;
  position: relative;
  font-size: 18px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: '';
}
.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 1.5em;
}
.tag-poi{
    color: #FFF;
    margin-right: 4px;
    padding: 2px 4px;
    display:inline-block;
    text-transform:uppercase;
}
.tag-origen{
    background: #9ad302;
}
.tag-destino{
    background: #485da8;
}
.tag-punto_pasada{
    background: #0fd3ff;
}
.tag-desde{
    background: #d30b0b;
}
.tag-hasta{
    background: #b89a05;
}
/* Reserva */
.tag-estado-reserva{
    color: #FFF;
    margin-right: 4px;
    padding: 2px 4px;
    display:inline-block;
    text-transform:uppercase;
}
.tag-pdt_confirmar{
    background: #f2bf41;
}
.tag-confirmada{
    background: #1ebc1c;
}
.tag-rechazada{
    background: #ff2424;
}

@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
.mapa-nueva-ruta{
    width: 100%;
    height: 400px;
    background-color: grey;
    margin-bottom: 20px;
}
.nueva-ruta .search-wrapper{
    display: flex;
}
.nueva-ruta .search-wrapper input{
    flex-grow: 1;
}
.panel-body-title{
    font-weight: 700;    
}
.nueva-ruta .panel-body-title{
    background: #5b69bc;
    color: #fff;
    padding: 5px;
}
.punto{
    border: 2px solid #a0a0a0;
    border-radius: 15px;
    padding: 10px;
    display: block;
    background: #f0f8ff;
    margin-bottom: 5px;
}
.punto-wrapper{
    display:inline-block;
    padding: 0 20px;      
    width: 90%;
}
.punto-wrapper p{
    margin-bottom: 5px;
}
.punto-wrapper .nombre{
    font-weight: 600;
}
.punto-desc{
    display: flex;
}
.punto-desc .coordenadas{
    flex-grow: 1;
}
.punto-desc .label{
    font-size: 100%;
    color: #fff;
    background: #008000;
    display:none;
}
.punto-desc .label .origen,
.punto-desc .label .destino{
    display: none;
}
.punto .acciones{
    display:inline-block;
    width: 10%;
}
.punto .acciones button{
/*    position: absolute;
    right:0;
    top: 50%;
    transform: translateY(-50%);*/
}
.punto:nth-child(1) .punto-wrapper .punto-desc .label,
.punto:nth-child(1) .punto-wrapper .punto-desc .label .origen{
    display: inline-block;
}
.punto:nth-child(n+2):last-child .punto-wrapper .punto-desc .label,
.punto:nth-child(n+2):last-child .punto-wrapper .punto-desc .label .destino{
    display: inline-block;
}
.pois-error{
    background-color: #e7575e;
    border: 1px solid #BF2D35;
    border-radius: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    color: #fff;   
}